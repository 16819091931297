// ** Redux Imports
import { combineReducers } from 'redux'
import * as ReduxStore from '@uppy/store-redux'
// ** Reducers Imports

// ** TO DO: Change user data from auth to user
import auth from './auth'
import user from './user'

import navbar from './navbar'
import layout from './layout'
import settings from './settings'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import inventory from '@src/views/apps/inventory/store/reducer'
import myProducts from '@src/views/apps/products/store/reducer'
import packages from '@src/views/apps/packages/store/reducer'
import orders from '@src/views/apps/orders/store/reducer'
import logistics from '@src/views/apps/logistics/store/reducer'
import shipments from '@src/views/apps/shipments/store/reducer'
import shippingBatches from '@src/views/apps/shippingBatchs/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  settings,
  ecommerce,
  inventory,
  myProducts,
  logistics,
  packages,
  orders,
  layout,
  shipments,
  shippingBatches,
  uppy: ReduxStore.reducer
})

export default rootReducer
