// ** Initial State
const initialState = {
  shippingBatches: [],
  total: 0,
  params: { q: '', page: 1, perPage: 10 },
  totalList: 0,
  isLoaded: false,
  isError: false,
  summary: {}
}

// 2
const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA_SHIPPING_BATCH':
      return {
        ...state,
        shippingBatches: action.data,
        total: action.total,
        params: action.params,
        totalList: action.totalList,
        isLoaded: true,
        isError: false,
        summary: action.summary
      }
    case 'CLEAR_DATA_SHIPPING_BATCH':
      return {
        ...state,
        shippingBatches: [],
        total: 0,
        params: initialState.params,
        totalList: 0,
        isLoaded: false,
        isError: false,
        summary: {}
      }
    case 'ERROR_SHIPPING_BATCH':
      return {
        ...state,
        isLoaded: false,
        isError: true
      }
    default:
      return state
  }
}

export default DataTablesReducer
