// import ordersExample from './ordersExample.test'
// import proposalExample from './proposalExample.test'

const initialState = {
  isLoading: true,
  isError: null,
  orders: [
    // ...ordersExample
  ],
  selectedOrder: null,
  changes: {
    id: '',
    quantity: 0,
    obs: '',
    isChanged: false,
    isSaved: true,
    mockupUrl: []
  },

  // ** Order confirm states

  confirm: {
    confirmModalOpen: false,
    logistic: {
      option: '',
      shippingDeadline: ''
    },
    payment: {
      option: '',
      paymentDeadline: ''
    },
    observations: ''
  },

  tracking: {
    trackingView: false
  },

  // ** Proposal states

  proposal: {
    id: 0,
    dateIssued: '',
    dueDate: '',
    // ** OK
    to: {
      name: '',
      company: '',
      cnpj: '',
      address: '',
      cep: '',
      city: '',
      state: '',
      phone: '',
      email: ''
    },

    items: [
      // ...proposalExample.items
    ],
    totals: {
      totalItems: 0,
      totalQuantity: 0,
      totalOther: 0,
      totalItemsPrice: 0,
      shipping: 0,
      totalProposal: 0,
      finalTotal: 0
    },

    salesperson: '',
    // ** ?
    deadline: '',
    observation: ''
  }
}

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ORDERS_REQUEST':
      return {
        ...state,
        isLoading: true,
        isError: null
      }
    case 'SET_ORDERS_ERROR':
      return {
        ...state,
        isLoading: false,
        isError: true
      }
    case 'GET_ORDERS':
      return {
        ...state,
        isLoading: false,
        isError: null,
        orders: action.data
      }

    case 'SET_ORDERS':
      return {
        ...state,
        orders: action.data
      }

    case 'GET_ORDER':
      return {
        ...state,
        selectedOrder: { ...action.data }
      }

    case 'SET_SELECTED_ORDER':
      return {
        ...state,
        selectedOrder: action.data
      }

    case 'UPDATE_ITEM':
      return {
        ...state,
        selectedOrder: { ...state.selectedOrder, items: action.data.items }
      }

    case 'ADD_ORDER_ITEM':
      return {
        ...state,
        selectedOrder: { ...state.selectedOrder, items: action.data.items }
      }

    case 'REMOVE_ORDER_ITEM':
      return {
        ...state,
        selectedOrder: { ...state.selectedOrder, items: action.data.items }
      }

    case 'SET_INITIAL_QUANTITY':
      return {
        ...state,
        changes: {
          ...state.changes,
          quantity: action.data.quantity
        }
      }

    case 'SET_CHANGES':
      return {
        ...state,
        changes: {
          ...state.changes,
          ...action.data.changes,
          isChanged: true,
          isSaved: false
        }
      }

    case 'SAVE_CHANGES':
      return {
        ...state,
        changes: {
          id: action.data.id,
          quantity: action.data.quantity,
          obs: action.data.obs,
          mockupUrl: action.data.mockupUrl,
          isChanged: false,
          isSaved: true
        }
      }

    case 'DISCARD_CHANGES':
      return {
        ...state,
        changes: {
          ...initialState.changes,
          mockupUrl: []
        }
      }

    case 'GET_PROPOSAL':
      return {
        ...state,
        proposal: {
          ...state.proposal,
          ...action.data.proposal
        }
      }

    case 'APPROVE_ORDER':
      return { ...state }

    case 'OPEN_ORDER_CONFIRMATION_MODAL':
      return {
        ...state,
        confirm: {
          ...state.confirm,
          confirmModalOpen: action.data.boolean
        }
      }

    case 'SET_FULLFILL_DATA':
      return {
        ...state,
        confirm: {
          ...state.confirm,
          payment: {
            ...state.confirm.payment,
            ...action.data.payment
          },
          logistic: {
            ...state.confirm.logistic,
            ...action.data.logistic
          }
        }
      }

    case 'SET_INVOICING_DATA':
      return {
        ...state,
        confirm: {
          ...state.confirm,
          company: action.data.company,
          address: action.data.address,
          contact: action.data.contact
        }
      }

    case 'SET_OBSERVATION_DATA':
      return {
        ...state,
        confirm: {
          ...state.confirm,
          observations: action.data.observations
        }
      }

    case 'CONFIRM_ORDER':
      return {
        ...state
      }

    case 'TOGGLE_TIMELINE_VIEW':
      return {
        ...state,
        tracking: {
          ...state.tracking,
          trackingView: action.data.boolean
        }
      }

    default:
      return state
  }
}

export default orderReducer
