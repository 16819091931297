import { useEffect, useState } from 'react'

import html2canvas from 'html2canvas'
import { Smartphone, Trash } from 'react-feather'
import { Button, Spinner } from 'reactstrap'

import PropTypes from 'prop-types'

// ** Styles

import '@styles/custom/components/screenshot-button.scss'

const ScreenshotButton = (props) => {
  const { onScreenshotTaken, screenshot,inputRef, ...rest } = props

  const [isTakingScreenshot, setIsTakingScreenshot] = useState(false)

  async function handleTakeScreenshot() {
    setIsTakingScreenshot(true)

    const canvas = await html2canvas(document.querySelector('html'), {
      height: window.innerHeight,
      width: window.innerWidth
    })

    const base64image = canvas.toDataURL('image/png')

    onScreenshotTaken(base64image)
    setIsTakingScreenshot(false)
  }
  useEffect(() => {
    if (!!inputRef) {
      handleTakeScreenshot()
    }
    return () => {
      if(inputRef)inputRef.current = null
    };
  }, [inputRef]);
  if (screenshot && !inputRef) {
    return (
      <Button.Ripple
        type="button"
        className="screenshot-button"
        outline
        style={{
          backgroundImage: `url(${screenshot})`,
          backgroundPosition: 'right bottom',
          backgroundSize: 180
        }}
        onClick={() => onScreenshotTaken(null)}
        {...rest}
      >
        <Trash size={20} />
      </Button.Ripple>
    )
  }

  return (
    <Button.Ripple
      type="button"
      className="screenshot-button"
      onClick={handleTakeScreenshot}
      disabled={isTakingScreenshot}
      {...rest}
    >
      {isTakingScreenshot ? <Spinner size="sm" /> : <Smartphone size={20} />}
    </Button.Ripple>
  )
}

ScreenshotButton.propTypes = {
  onScreenshotTaken: PropTypes.func.isRequired,
  screenshot: PropTypes.string,
  rest: PropTypes.any,
  handleTakeScreenshot: PropTypes.func,
}


export default ScreenshotButton

