export const atlasUrl = process.env.REACT_APP_ATLAS_URL

// ** Auth Endpoints
const config = {
  baseURL: atlasUrl,

  loginEndpoint: '/auth/login',
  registerEndpoint: '/auth/users',
  resetEndpoint: '/auth/reset-password',
  refreshEndpoint: '/auth/refresh',
  logoutEndpoint: '/auth/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. "Authorization": `Bearer ${token}`
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}

export default config
