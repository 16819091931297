export function sortProducts({ groupBy, products, sortBy }) {
  const isAscending = sortBy === 'ascending'
  let sortedProducts

  if (groupBy !== 'all') {
    sortedProducts = [...products].reverse()
  } else {
    sortedProducts = [...products].sort((a, b) => {
      const compareResult = a.itemName.localeCompare(b.itemName)
      return isAscending ? compareResult : -compareResult
    })
  }
  return sortedProducts
}

export function groupedByCategory({ raw, hasStock }) {
  const filter = stockBy({ raw, stockBy: hasStock })
  const categorizedItems = filter.reduce((acc, item) => {
    const { category } = item

    if (!acc[category]) {
      acc[category] = []
    }

    acc[category].push(item)

    return acc
  }, {})

  const sortedCategories = Object.keys(categorizedItems).sort()

  const groupedItems = sortedCategories.map((category) => ({
    [category]: categorizedItems[category]
  }))

  return groupedItems
}

export function groupedBy({ groupBy, raw, products, hasStock }) {
  if (groupBy === 'category') {
    return groupedByCategory({ raw, hasStock })
  }
  if (groupBy === 'all') {
    return stockBy({ raw, stockBy: hasStock })
  }
  return raw
}

export const stockBy = ({ stockBy, raw }) => {
  if (stockBy === 'all' || stockBy === '') {
    return raw
  }
  return raw.filter((item) => !!item.quantityInStock === stockBy)
}

export const filterBy = ({ raw, query }) => {
  return raw.filter((item) => {
    return item.itemName.toLowerCase().includes(query.toLowerCase())
  })
}
