/* eslint-disable react-hooks/rules-of-hooks */
import { useJwt } from '@src/@core/auth/jwt/useJwt'
import { store } from '@store/storeConfig/store'
import { handleLogout } from '@store/actions/auth'
/**
 * @typedef {import('./auth')} Auth
 * @typedef {import('./balance')} Balance
 * @typedef {import('./ecommerce')} Ecommerce
 * @typedef {import('./faq')} Faq
 * @typedef {import('./feedback')} Feedback
 * @typedef {import('./inventory')} Inventory
 * @typedef {import('./misc')} Misc
 * @typedef {import('./order')} Order
 * @typedef {import('./subscription')} Subscription
 * @typedef {import('./user')} User
 */

export const atlasUrl = process.env.REACT_APP_ATLAS_URL

/**
 * @type {{
 *   auth: Promise<Auth>,
 *   balance: Promise<Balance>,
 *   ecommerce: Promise<Ecommerce>,
 *   faq: Promise<Faq>,
 *   feedback: Promise<Feedback>,
 *   inventory: Promise<Inventory>,
 *   misc: Promise<Misc>,
 *   order: Promise<Order>,
 *   subscription: Promise<Subscription>,
 *   user: Promise<User>
 * }}
 */
const atlasService = {
  auth: import('./auth'),
  balance: import('./balance'),
  ecommerce: import('./ecommerce'),
  faq: import('./faq'),
  feedback: import('./feedback'),
  inventory: import('./inventory'),
  misc: import('./misc'),
  order: import('./order'),
  subscription: import('./subscription'),
  user: import('./user')
}

const { jwt } = useJwt()
jwt.configLogout(store.dispatch, handleLogout)

/**
 * @type {{
 *   auth: Auth,
 *   balance: Balance,
 *   ecommerce: Ecommerce,
 *   faq: Faq,
 *   feedback: Feedback,
 *   inventory: Inventory,
 *   misc: Misc,
 *   order: Order,
 *   subscription: Subscription,
 *   user: User
 * }}
 */
const atlasApi = jwt.atlas()

Object.entries(atlasService).forEach(([key, value]) => {
  return new Promise((resolve, reject) => {
    /**
     * @param {Auth | Balance | Ecommerce | Faq | Feedback | Inventory | Misc | Order | Subscription | User} res
     */
    value
      .then((res) => {
        atlasApi[key] = res
        resolve()
      })
      /**
       * @param {any} err
       */
      .catch((err) => {
        reject(err)
      })
  })
})

export default atlasApi
