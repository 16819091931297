import axios from 'axios'

const atlasUrl = 'https://api.devbeuni.xyz/atlas/'

const devUrl = 'http://localhost:4010/'

const resourceToken = process.env.REACT_APP_X_RESOURCE_TOKEN || "string"

const atlasApi = axios.create({
	baseURL: atlasUrl,
	headers:{
		"Accept": "application/json",
		"Content-Type": "application/json",
		"X-Resource-Token": resourceToken,

		"Access-Control-Allow-Origin": true,

	}
});

export default atlasApi