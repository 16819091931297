// ** Initial State
export const initialState = {
  inventory: {
    groupBy: 'order'
  },
  user: {
    originContactFields: {
      email: null,
      phone: null
    }
  },
  kitCreator: {
    showTour: true
  },
  products: {
    minimumQuantity: 50
  }
}

const settingsRedurcer = (state = initialState, action) => {
  switch (action.type) {
    case '@settings/SET_INVENTORY_GROUP':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          groupBy: action.payload.groupBy
        }
      }
    case '@settings/SET_ORIGIN_CONTACT_FIELDS':
      return {
        ...state,
        user: {
          ...state.user,
          originContactFields: {
            ...(state.user?.originContactFields ||
              initialState.user.originContactFields),
            ...action.payload.originContactFields
          }
        }
      }
    case '@settings/SET_KIT_CREATOR_TOUR':
      return {
        ...state,
        kitCreator: {
          ...state.kitCreator,
          showTour: action.payload.showTour
        }
      }
    default:
      return state
  }
}

export default settingsRedurcer
