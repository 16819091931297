// ** React Imports

/*eslint-disable import/first  */

import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import ErrorBoundary from './errorBoundary'
// ** Redux Imports
import { Provider } from 'react-redux'
import { store, persistor } from './redux/storeConfig/store'

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability'
import { ToastContainer } from 'react-toastify'
import { AbilityContext } from './utility/context/Can'
import { ThemeContext } from './utility/context/ThemeColors'
import { IntlProviderWrapper } from './utility/context/Internationalization'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** Fake Database
import './@fake-db'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import './index.css'

import { PersistGate } from 'redux-persist/integration/react'

// ** Service Worker
import * as serviceWorker from './serviceWorker'

// ** Analytics

import ReactGA from 'react-ga4'
import { hotjar } from 'react-hotjar'
import { PostHogProvider } from 'posthog-js/react'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

// ** Sentry
import * as Sentry from '@sentry/react'
import {
  ExtraErrorData as ExtraErrorDataIntegration,
  CaptureConsole as CaptureConsoleIntegration,
  HttpClient as HttpClientIntegration
} from '@sentry/integrations'

import { history } from './history'

Sentry.init({
  dsn: 'https://25d445795c1c4951994702d2f0528605@o4504023066607616.ingest.sentry.io/4504023070212096',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 12,
  tracesSampleRate: 1.0,
  enabled: process.env.REACT_APP_NODE_ENV !== 'development',
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null
    }
    return event
  },
  integrations: [
    new ExtraErrorDataIntegration({
      depth: 4
    }),
    new CaptureConsoleIntegration({
      levels: ['error']
    }),
    new HttpClientIntegration({
      failedRequestStatusCodes: [
        400, 401, 403, 404, 408, 429, 500, 502, 503, 504
      ],
      failedRequestTargets: process.env.REACT_APP_NODE_ENV !==
        'development' && [process.env.REACT_APP_ATLAS_URL]
    }),
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    }),
    new Sentry.Replay()
  ]
})

// ** Init Analytics
process.env.REACT_APP_GA_MEASUREMENT_ID &&
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID)
hotjar.initialize(
  process.env.REACT_APP_HOTJAR_ID,
  process.env.REACT_APP_HOTJAR_VERSION
)

// Feature Flag for Redeem Pages
window.FEATURE_REDEEM_PAGES = true

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<Spinner />}>
        <ErrorBoundary>
          <PostHogProvider
            apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
            options={{
              api_host: process.env.REACT_APP_PUBLIC_POSTHOG_API_HOST,
              ui_host: process.env.REACT_APP_PUBLIC_POSTHOG_UI_HOST
            }}
          >
            <AbilityContext.Provider value={ability}>
              <ThemeContext>
                <IntlProviderWrapper>
                  <LazyApp />
                  <ToastContainer newestOnTop />
                </IntlProviderWrapper>
              </ThemeContext>
            </AbilityContext.Provider>
          </PostHogProvider>
        </ErrorBoundary>
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
if (module.hot) {
  module.hot.accept()
}
