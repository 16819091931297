import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
export const atlasUrl = process.env.REACT_APP_ATLAS_URL
export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  // ** Login URL to redirect
  loginUrl = '/login'

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        // ** Set base URL
        config.baseURL = this.jwtConfig.baseURL

        // ** Get token from localStorage
        const accessToken = this.getToken()

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }

        config.headers.Accept = 'application/json'
        config.headers['Content-Type'] = 'application/json'

        return config
      },
      (error) => Promise.reject(error)
    )

    // ** Response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // ** if (status === 403) {
        if (response && response.status === 403) {
          if (!this.isAlreadyFetchingAccessToken) {

            this.isAlreadyFetchingAccessToken = true

            this.refreshToken()
              .then((r) => {
                this.isAlreadyFetchingAccessToken = false

                // ** Update accessToken in localStorage
                this.setToken(r.data.accessToken)
                this.setRefreshToken(r.data.refreshToken)

                this.onAccessTokenFetched(r.data.accessToken)
              })
              .catch((err) => {
                const { response } = err
                const { data } = response

                const validErrorCodes = [
                  'ERR_REFRESH_TOKEN_INVALID',
                  'ERR_REFRESH_TOKEN_NOT_VALID_ANYMORE'
                ]

                if (
                  (data.errors &&
                    data.errors.some((error) =>
                      validErrorCodes.includes(error.code)
                    )) ||
                  validErrorCodes.includes(data.code)
                ) {
                  this.logout()
                }
              })
          }

          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              originalRequest.headers['Access-Control-Allow-Origin'] = true

              resolve(axios(originalRequest))
            })
          })

          return retryOriginalRequest
        }

        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    )
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  reset(...args) {
    return axios.put(this.jwtConfig.resetEndpoint, ...args)
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken()
    })
  }

  logout() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)

    this.jwtConfig.logout(this.jwtConfig.logoutAction())
  }

  configLogout(dispatch, action) {
    this.jwtConfig.logout = dispatch
    this.jwtConfig.logoutAction = action
  }

  atlas() {
    return axios
  }
  atlasNoAuth() {
    return axios.create({
      baseURL: atlasUrl,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
  }
}
