// ** React Imports
import { useEffect, useState, createContext } from 'react'

// ** Create Context
const ThemeColors = createContext()

const ThemeContext = ({ children }) => {
  // ** State
  const [colors, setColors] = useState({})

  //** ComponentDidMount
  useEffect(() => {
    if (window !== 'undefined') {
      //** Get variable value
      const getHex = (color) =>
        window.getComputedStyle(document.body).getPropertyValue(color).trim()

      //** Colors obj
      const obj = {
        primary: {
          light: getHex('--primary').concat('1a'),
          main: getHex('--primary')
        },
        secondary: {
          light: getHex('--secondary').concat('1a'),
          main: getHex('--secondary')
        },
        success: {
          light: getHex('--success').concat('1a'),
          main: getHex('--success')
        },
        danger: {
          light: getHex('--danger').concat('1a'),
          main: getHex('--danger')
        },
        warning: {
          light: getHex('--warning').concat('1a'),
          main: getHex('--warning')
        },
        info: {
          light: getHex('--info').concat('1a'),
          main: getHex('--info')
        },
        dark: {
          light: getHex('--dark').concat('1a'),
          main: getHex('--dark')
        },
        order: {
          orderStatus: {
            'Pedido sendo processado': {
              light: '#ff6f2c'.concat('1a'),
              main: '#e5e7eb',
              text: '#5e5873'
            },
            'Aguardando aprovação': {
              light: '#ff08c2'.concat('1a'),
              main: '#ff6f2c',
              text: '#ffffff'
            },
            'Em produção': {
              light: '#5555'.concat('1a'),
              main: '#e5e7eb',
              text: '#5e5873'
            },
            'Aguardando pagamento': {
              light: '#FCB400'.concat('1a'),
              main: '#FCB400',
              text: '#ffffff'
            },
            Armazenado: {
              light: '#20C933'.concat('1a'),
              main: '#20C933',
              text: '#ffffff'
            },
            Entregue: {
              light: '#20C933'.concat('1a'),
              main: '#20C933',
              text: '#ffffff'
            },
            'Em trânsito': {
              light: '#ff6f2c'.concat('1a'),
              main: '#e5e7eb',
              text: '#5e5873'
            }
          },
          itemStatus: {
            'Aprovar item': {
              light: '#ff6f2c'.concat('1a'),
              main: '#ff6f2c'
            },
            'Mudanças requisitadas': {
              light: '#ff08c2'.concat('1a'),
              main: '#ff08c2'
            },
            'Mockup sendo criado': {
              light: '#666666'.concat('1a'),
              main: '#666666'
            },
            'Aprovar mockup': {
              light: '#FCB400'.concat('1a'),
              main: '#FCB400'
            },
            'Criar mockups': {
              light: '#20C933'.concat('1a'),
              main: '#20C933'
            },
            'Mockup aprovado': {
              light: '#20C933'.concat('1a'),
              main: '#20C933'
            },
            'Item aprovado': {
              light: getHex('--success').concat('1a'),
              main: getHex('--success')
            }
            // "Item aprovado": {
            //   light: "#20D9D2".concat("1a"),
            //   main: "#20D9D2"
            // }
          }
        },
        shipping: {
          individualStatus: {
            'Aguardando pagamento': {
              light: '#fcb401'.concat('1a'),
              main: '#fcb401'
            },
            'Separando itens para despacho': {
              light: '#444444'.concat('1a'),
              main: '#444444'
            },
            'A caminho do destinatário': {
              light: '#8a46ff'.concat('1a'),
              main: '#8a46ff'
            },
            Entregue: {
              light: '#23c635'.concat('1a'),
              main: '#23c635'
            },
            Erro: {
              light: '#f72b60'.concat('1a'),
              main: '#f72b60'
            },
            'Cancelado pelo usuário': {
              light: '#ba1e45'.concat('1a'),
              main: '#ba1e45'
            },
            'Voltou para o galpão': {
              light: '#18bffe'.concat('1a'),
              main: '#18bffe'
            },
            'Envio completo': {
              light: '#20C933'.concat('1a'),
              main: '#20C933'
            },
            cancelado: {
              light: '#201f1d'.concat('1a'),
              main: '#201f1d'
            },
            'Pacialmente entregue': {
              light: '#ff9f43'.concat('1a'),
              main: '#ff9f43'
            },
            'Envio único': {
              light: '#17a2b8'.concat('1a'),
              main: '#17a2b8'
            }
          },
          batchStatus: {}
        }
      }

      setColors({ ...obj })
    }
  }, [])

  return (
    <ThemeColors.Provider value={{ colors }}>{children}</ThemeColors.Provider>
  )
}

export { ThemeColors, ThemeContext }
