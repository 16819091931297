// import { selectedItemsExample } from './initialState.test'

const initialState = {
  minimalWorkDays: 18,
  selectedItems: [
    // ...selectedItemsExample
  ],

  selectedInventoryItems: [],
  steps: {
    stepUploading: false,
    stepValidating: false,
    stepQuoting: false
  },
  validation: {
    updatedRows: [],
    updatedPositions: [],
    changesPosition: [],
    templateLink: '',
    hasErrors: false,
    canShowDataGrid: false,
    isValidatingRow: false,
    hasBeenVerified: false,
    csvData: [],
    csvErrors: [],
    messageErrors: [
      {
        message:
          '> *A linha do pacote #2 contém erros.* => O CPF não é válido. Verifique se ele tem 11 dígitos e, caso tenha, reveja a veracidade do número.'
      }
    ],
    messageErrorsType: '',
    csvLink: '' // updated after client interaction || 'https://aasdfasdf',
  },
  shipping: {
    hasErrors: false,
    selectedShippingMethod: 'single-shipping',
    shippingOptions: [
      {
        id: 1,
        name: 'Mais rápido',
        deliveryRange: {
          min: 2,
          estMinDate: '2022-02-08T17:09:02.556Z',
          max: 3,
          estMaxDate: '2022-02-09T17:09:02.556Z'
        },
        costs: {
          shipping: 58.41,
          discount: 3.4140000000000015,
          outsideProductsShipping: 0,
          material: 2.9750000000000005,
          fulfillment: 8,
          total: 65.971
        },
        breakdown: {
          data: [
            {
              Pacote: '#1',
              Destinatario: 'Alexandre Gayoso Muchão',
              CPF: '42099317801',
              CNPJ: '',
              Email: 'alexandre.muchao@geradornv.com.br',
              Telefone: '(49) 99999-9999',
              'Rua ou Avenida': 'Rua SC – 002',
              Numero: '9999',
              Complemento: '',
              Bairro: 'Azambuja',
              Cidade: 'Brusque',
              UF: 'SC',
              CEP: '88354-093',
              'Produto_361 Quantidade': '3',
              'Prazo de entrega': '2 a 3 dias úteis',
              'Custo original do envio': 'R$ 58.41',
              'Desconto neste envio': 'R$ 3.41',
              'Custo de materiais para fazer o envio (ex: embalagens)':
                'R$ 2.98',
              'Custo de fulfillment': 'R$ 8.00',
              'Custo final do envio': 'R$ 65.97'
            }
          ],
          headers: [
            {
              key: 'Pacote',
              label: 'Pacote'
            },
            {
              key: 'Destinatario',
              label: 'Destinatario'
            },
            {
              key: 'CPF',
              label: 'CPF'
            },
            {
              key: 'CNPJ',
              label: 'CNPJ'
            },
            {
              key: 'Email',
              label: 'Email'
            },
            {
              key: 'Telefone',
              label: 'Telefone'
            },
            {
              key: 'Rua ou Avenida',
              label: 'Rua ou Avenida'
            },
            {
              key: 'Numero',
              label: 'Numero'
            },
            {
              key: 'Complemento',
              label: 'Complemento'
            },
            {
              key: 'Bairro',
              label: 'Bairro'
            },
            {
              key: 'Cidade',
              label: 'Cidade'
            },
            {
              key: 'UF',
              label: 'UF'
            },
            {
              key: 'CEP',
              label: 'CEP'
            },
            {
              key: 'Produto_361 Quantidade',
              label: 'Produto_361 Quantidade'
            },
            {
              key: 'Prazo de entrega',
              label: 'Prazo de entrega'
            },
            {
              key: 'Custo original do envio',
              label: 'Custo original do envio'
            },
            {
              key: 'Desconto neste envio',
              label: 'Desconto neste envio'
            },
            {
              key: 'Custo de materiais para fazer o envio (ex: embalagens)',
              label: 'Custo de materiais para fazer o envio (ex: embalagens)'
            },
            {
              key: 'Custo de fulfillment',
              label: 'Custo de fulfillment'
            },
            {
              key: 'Custo final do envio',
              label: 'Custo final do envio'
            }
          ],
          additionalData: [
            {
              shipping_company: 'Correios',
              shipping_service: 'ME_SEDEX_2',
              delivery_time: 3,
              packages: [
                {
                  format: 'box',
                  weight: '0.30',
                  insurance_value: '0.00',
                  products: [
                    {
                      id: 'recRddibmPGt5BOTm',
                      quantity: 3
                    }
                  ],
                  dimensions: {
                    height: 9,
                    width: 19,
                    length: 19.5
                  }
                }
              ],
              shippingBoxes: ['recvLry3zP6snNWRZ']
            }
          ]
        }
      },
      {
        id: 2,
        name: 'Mais barato',
        deliveryRange: {
          min: 6,
          estMinDate: '2022-02-14T17:09:02.556Z',
          max: 7,
          estMaxDate: '2022-02-15T17:09:02.556Z'
        },
        costs: {
          shipping: 27.5,
          discount: 8.744,
          outsideProductsShipping: 0,
          material: 2.9750000000000005,
          fulfillment: 8,
          total: 29.731
        },
        breakdown: {
          data: [
            {
              Pacote: '#1',
              Destinatario: 'Alexandre Gayoso Muchão',
              CPF: '42099317801',
              CNPJ: '',
              Email: 'alexandre.muchao@geradornv.com.br',
              Telefone: '(49) 99999-9999',
              'Rua ou Avenida': 'Rua SC – 002',
              Numero: '9999',
              Complemento: '',
              Bairro: 'Azambuja',
              Cidade: 'Brusque',
              UF: 'SC',
              CEP: '88354-093',
              'Produto_361 Quantidade': '3',
              'Prazo de entrega': '6 a 7 dias úteis',
              'Custo original do envio': 'R$ 27.50',
              'Desconto neste envio': 'R$ 8.74',
              'Custo de materiais para fazer o envio (ex: embalagens)':
                'R$ 2.98',
              'Custo de fulfillment': 'R$ 8.00',
              'Custo final do envio': 'R$ 29.73'
            }
          ],
          headers: [
            {
              key: 'Pacote',
              label: 'Pacote'
            },
            {
              key: 'Destinatario',
              label: 'Destinatario'
            },
            {
              key: 'CPF',
              label: 'CPF'
            },
            {
              key: 'CNPJ',
              label: 'CNPJ'
            },
            {
              key: 'Email',
              label: 'Email'
            },
            {
              key: 'Telefone',
              label: 'Telefone'
            },
            {
              key: 'Rua ou Avenida',
              label: 'Rua ou Avenida'
            },
            {
              key: 'Numero',
              label: 'Numero'
            },
            {
              key: 'Complemento',
              label: 'Complemento'
            },
            {
              key: 'Bairro',
              label: 'Bairro'
            },
            {
              key: 'Cidade',
              label: 'Cidade'
            },
            {
              key: 'UF',
              label: 'UF'
            },
            {
              key: 'CEP',
              label: 'CEP'
            },
            {
              key: 'Produto_361 Quantidade',
              label: 'Produto_361 Quantidade'
            },
            {
              key: 'Prazo de entrega',
              label: 'Prazo de entrega'
            },
            {
              key: 'Custo original do envio',
              label: 'Custo original do envio'
            },
            {
              key: 'Desconto neste envio',
              label: 'Desconto neste envio'
            },
            {
              key: 'Custo de materiais para fazer o envio (ex: embalagens)',
              label: 'Custo de materiais para fazer o envio (ex: embalagens)'
            },
            {
              key: 'Custo de fulfillment',
              label: 'Custo de fulfillment'
            },
            {
              key: 'Custo final do envio',
              label: 'Custo final do envio'
            }
          ],
          additionalData: [
            {
              shipping_company: 'Jadlog',
              shipping_service: 'ME_.Com_4',
              delivery_time: 7,
              packages: [
                {
                  format: 'box',
                  weight: '0.30',
                  insurance_value: '0.00',
                  products: [
                    {
                      id: 'recRddibmPGt5BOTm',
                      quantity: 3
                    }
                  ],
                  dimensions: {
                    height: 9,
                    width: 19,
                    length: 19.5
                  }
                }
              ],
              shippingBoxes: ['recvLry3zP6snNWRZ']
            }
          ]
        }
      },
      {
        id: 3,
        name: 'Maior custo-benefício',
        deliveryRange: {
          min: 6,
          estMinDate: '2022-02-14T17:09:02.556Z',
          max: 7,
          estMaxDate: '2022-02-15T17:09:02.556Z'
        },
        costs: {
          shipping: 27.5,
          discount: 8.744,
          outsideProductsShipping: 0,
          material: 2.9750000000000005,
          fulfillment: 8,
          total: 29.731
        },
        breakdown: {
          data: [
            {
              Pacote: '#1',
              Destinatario: 'Alexandre Gayoso Muchão',
              CPF: '42099317801',
              CNPJ: '',
              Email: 'alexandre.muchao@geradornv.com.br',
              Telefone: '(49) 99999-9999',
              'Rua ou Avenida': 'Rua SC – 002',
              Numero: '9999',
              Complemento: '',
              Bairro: 'Azambuja',
              Cidade: 'Brusque',
              UF: 'SC',
              CEP: '88354-093',
              'Produto_361 Quantidade': '3',
              'Prazo de entrega': '6 a 7 dias úteis',
              'Custo original do envio': 'R$ 27.50',
              'Desconto neste envio': 'R$ 8.74',
              'Custo de materiais para fazer o envio (ex: embalagens)':
                'R$ 2.98',
              'Custo de fulfillment': 'R$ 8.00',
              'Custo final do envio': 'R$ 29.73'
            }
          ],
          headers: [
            {
              key: 'Pacote',
              label: 'Pacote'
            },
            {
              key: 'Destinatario',
              label: 'Destinatario'
            },
            {
              key: 'CPF',
              label: 'CPF'
            },
            {
              key: 'CNPJ',
              label: 'CNPJ'
            },
            {
              key: 'Email',
              label: 'Email'
            },
            {
              key: 'Telefone',
              label: 'Telefone'
            },
            {
              key: 'Rua ou Avenida',
              label: 'Rua ou Avenida'
            },
            {
              key: 'Numero',
              label: 'Numero'
            },
            {
              key: 'Complemento',
              label: 'Complemento'
            },
            {
              key: 'Bairro',
              label: 'Bairro'
            },
            {
              key: 'Cidade',
              label: 'Cidade'
            },
            {
              key: 'UF',
              label: 'UF'
            },
            {
              key: 'CEP',
              label: 'CEP'
            },
            {
              key: 'Produto_361 Quantidade',
              label: 'Produto_361 Quantidade'
            },
            {
              key: 'Prazo de entrega',
              label: 'Prazo de entrega'
            },
            {
              key: 'Custo original do envio',
              label: 'Custo original do envio'
            },
            {
              key: 'Desconto neste envio',
              label: 'Desconto neste envio'
            },
            {
              key: 'Custo de materiais para fazer o envio (ex: embalagens)',
              label: 'Custo de materiais para fazer o envio (ex: embalagens)'
            },
            {
              key: 'Custo de fulfillment',
              label: 'Custo de fulfillment'
            },
            {
              key: 'Custo final do envio',
              label: 'Custo final do envio'
            }
          ],
          additionalData: [
            {
              shipping_company: 'Jadlog',
              shipping_service: 'ME_.Com_4',
              delivery_time: 7,
              packages: [
                {
                  format: 'box',
                  weight: '0.30',
                  insurance_value: '0.00',
                  products: [
                    {
                      id: 'recRddibmPGt5BOTm',
                      quantity: 3
                    }
                  ],
                  dimensions: {
                    height: 9,
                    width: 19,
                    length: 19.5
                  }
                }
              ],
              shippingBoxes: ['recvLry3zP6snNWRZ']
            }
          ]
        }
      }
    ],

    addressCount: 0,
    selectedShippingOption: '',
    shipmentLink: ''
  }
}

const logisticReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SHIPPING_METHOD':
      return {
        ...state,
        shipping: {
          ...state.shipping,
          selectedShippingMethod: action.data.selectedShippingMethod
        }
      }
    // ** Set logistic data
    // ** Set minimal working days for production
    case 'SET_MINIMAL_WORK_DAYS':
      return {
        ...state,
        minimalWorkDays: action.data.minimalWorkDays
      }

    case 'SET_SELECTED_ITEMS': //adiciona itens do inventário para serem selecionados p/ pacote
      return {
        ...state,
        selectedItems: action.data.selectedProducts
      }

    case 'SET_SELECTED_PACKAGE':
      return {
        ...state,
        selectedPackage: action.data.selectedPackage
      }

    case 'SET_SELECTED_INVENTORY': //adiciona itens do inventário ao pacote
      return {
        ...state,
        selectedInventoryItems: action.data.selectedInventoryItems
      }

    case 'SET_TEMPLATE_CSV': //adiciona link de template no estado
      return {
        ...state,
        validation: {
          ...state.validation,
          templatelink: action.data.csvLink
        }
      }

    case 'SET_SHEET_DATA': // adiciona dados geriDos de validação
      return {
        ...state,
        validation: {
          ...state.validation,
          hasErrors: false,
          errors: []
        },
        shipping: {
          ...state.shipping,
          shipmentLink: action.data.csvLink,
          addressCount: action.data.addressCount,
          shippingOptions: action.data.shippingOptions,
          selectedShippingOption: !!state.shipping.selectedShippingOption
            ? action.data.shippingOptions[
                state.shipping.selectedShippingOption.id
              ]
            : action.data.shippingOptions[2]
        }
      }

    case 'CONTROL_STEPS_VALIDATION': // controla os passos de validação (upload, validação com backend e cotação)
      return {
        ...state,
        steps: {
          ...state.steps,
          ...action.data.steps
        }
      }

    case 'SET_SHIPMENT_FILE': // adiciona os dados de um arquivo CSV no estado da aplicação
      return {
        ...state,
        validation: {
          ...state.validation,
          hasErrors: false,
          errors: [],
          csvLink: action.data.csvLink
        },
        shipping: {
          ...state.shipping,
          hasErrors: false,
          errors: [],
          shipmentLink: action.data.csvLink
        }
      }

    case 'CHANGE_CSV_DATA':
      return {
        ...state,
        validation: {
          ...state.validation,
          csvData: state.validation.csvData.map((dataRow, index) =>
            index === action.data.row
              ? { ...dataRow, [action.data.field]: action.data.newValue }
              : dataRow
          ),
          changesPosition: [
            ...state.validation.changesPosition,
            action.data.changedPosition
          ]
        }
      }

    case 'SET_VALIDATION_ROW_STATUS':
      return {
        ...state,
        validation: {
          ...state.validation,
          isValidatingRow: action.data.isValidatingRow
        }
      }

    case 'SET_UPDATED_ROW':
      return {
        ...state,
        validation: {
          ...state.validation,
          changesPosition: [...action.data.filteredChangesPosition],
          updatedPositions: [
            ...state.validation.updatedPositions,
            ...action.data.updatedPositions
          ],
          hasBeenVerified: true
        }
      }

    case 'REJECT_UPDATED_ROW': {
      const newMessageErrors = [
        ...state.validation.messageErrors,
        ...action.data.messageErrors
      ].filter(
        (error, index, array) =>
          index ===
          array.findIndex(
            (firstElement) => firstElement.message === error.message
          )
      )

      return {
        ...state,
        validation: {
          ...state.validation,
          changesPosition: [...action.data.filteredChangesPosition],
          updatedPositions: [
            ...state.validation.updatedPositions,
            ...action.data.updatedPositions
          ],
          hasErrors: true,
          hasBeenVerified: false,
          csvErrors: [...state.validation.csvErrors, ...action.data.newError],
          messageErrors: [...newMessageErrors]
        }
      }
    }

    case 'SET_DELETE_ROW':
      const deletedRow = Number(action.data.row)
      const deletedLine = Number(action.data.row + 1)
      const updatedIndexes = action.data.updatedIndexes

      const csvDataWithoutDeletedRow = state.validation.csvData
        .filter((_, index) => index !== deletedRow)
        .map((row, index) => ({
          ...row,
          lineIndex: Number(updatedIndexes[index].lineIndex),
          hashIndex: updatedIndexes[index].hashIndex
        }))
      const csvErrorsWithoutDeletedRow = state.validation.csvErrors
        .filter((staleError) => staleError.line !== deletedLine)
        .map((error) =>
          error.line > deletedLine ? { ...error, line: error.line - 1 } : error
        )
      const updatedPositionsWithoutDeletedRow =
        state.validation.updatedPositions
          .filter((stalePosition) => stalePosition.row !== deletedRow)
          .map((position) =>
            position.row > deletedRow
              ? { ...position, row: position.row - 1 }
              : position
          )
      const changesPositionWithoutDeletedRow = state.validation.changesPosition
        .filter((staleChange) => staleChange.row !== deletedRow)
        .map((change) =>
          change.row > deletedRow ? { ...change, row: change.row - 1 } : change
        )
      const hasBeenVerified =
        csvErrorsWithoutDeletedRow.length === 0 ? true : false
      return {
        ...state,
        validation: {
          ...state.validation,
          csvData: csvDataWithoutDeletedRow,
          csvErrors: csvErrorsWithoutDeletedRow,
          updatedPositions: updatedPositionsWithoutDeletedRow,
          changesPosition: changesPositionWithoutDeletedRow,
          hasBeenVerified
        }
      }

    case 'UPDATE_SHIPMMENT_ERRORS':
      return {
        ...state,
        validation: {
          ...state.validation,
          hasErrors: true,
          hasBeenVerified: false,
          csvErrors: action.data.csvErrors
        }
      }

    // case 'SET_SHIPMENT_ERROR': // DEPRECATED
    //   return {
    //     ...state,
    //     validation: {
    //       ...state.validation,
    //       hasErrors: true,
    //       errors: action.data.errors
    //     }
    //   }

    case 'SET_SELECTED_OPTION': //seleciona a opção do cliente
      return {
        ...state,
        shipping: {
          ...state.shipping,
          selectedShippingOption: action.data.option
        }
      }

    case 'ACCEPT_SHIPMENT': // depois de aceitar um shipment e o mesmo for finalizado com sucesso
      return {
        ...state,
        validation: {
          hasErrors: false,
          errors: [],
          csvLink: '' // updated after client interaction || 'https://aasdfasdf',
        },
        shipping: {
          hasErrors: false,
          errors: [],
          shippingOptions: [],
          selectedShippingOption: ''
        }
      }

    case 'REJECT_SHIPMENT': // depois de aceitar um shipment e o mesmo for finalizado com erros
      return {
        ...state,
        validation: {
          ...state.validation,
          csvLink: '',
          hasErrors: false,
          errors: action.data.errors
        },
        shipping: {
          ...state.shipping,
          hasErrors: true,
          errors: action.data.errors
        }
      }

    case 'REJECT_UPLOAD': // depois de aceitar um shipment e o mesmo for finalizado com erros
      return {
        ...state,
        validation: {
          ...state.validation,
          hasErrors: true,
          canShowDataGrid: action.data.canShowDataGrid,
          csvErrors: action.data.csvErrors,
          messageErrors: action.data.messageErrors,
          messageErrorsType: action.data.messageErrorsType,
          csvData: action.data.csvData
        },
        shipping: {
          ...state.shipping
        }
      }

    case 'CLEAR_SELECTED_ITEMS_LOGISCT':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          selectedInventoryItems: []
        }
      }

    case 'CLEANUP':
      return {
        ...state,
        validation: {
          updatedRows: [],
          updatedPositions: [],
          changesPosition: [],
          hasErrors: false,
          canShowDataGrid: false,
          isValidatingRow: false,
          hasBeenVerified: false,
          csvErrors: [],
          messageErrors: [],
          messageErrorsType: '',
          csvData: [],
          csvLink: '', // updated after client interaction || 'https://aasdfasdf',
          errors: []
        }
      }

    case 'CLEANUP_ERRORS':
      return {
        ...state,
        validation: {
          ...state.validation,
          hasBeenVerified: false,
          csvErrors: [],
          messageErrors: [],
          messageErrorsType: ''
          // csvData: []
        }
      }

    // case 'CLEAR_CART':
    //   let clearedCartData = { ...state, cart: [] }
    //   return { ...clearedCartData }

    // case 'GET_PRODUCT':
    //   return { ...state, productDetail: action.data.product }

    // case 'CLEAR_PRODUCT':
    //   let clearedProductData = { ...state, productDetail: {} }
    //   return { ...clearedProductData }

    // case 'CREATE_ORDER':
    //   return { ...state, cart: [] }

    // case 'UPDATE_CHECKOUT':
    //   return { ...state, checkout: action.data.checkout }

    // case 'GET_SWAG_PACKS':
    //   return {
    //     ...state,
    //     swagPacks: action.data.swagPacks,
    //     totalSwagPacks: action.data.total
    //   }
    // case 'SELECT_SWAG_PACK':
    //   return {
    //     ...state,
    //     productDetail: action.data.productDetail
    //   }

    default:
      return state
  }
}

export default logisticReducer
