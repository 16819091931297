// import { getUser } from '../../../utility/Utils'
// **  Initial State
// const initialState = getUser()
const initialState = {
  userData: {
    recipient_id: 0,
    id: 0,
    username: 'Visitante',
    first_name: 'Visitante',
    last_name: '',
    name: 'Visitante',
    email: 'seuemaildecontanto@email.com',
    nickname: 'Visitante',
    slug: 'visitor',
    roles: ['brands_v2'],
    capabilities: { read: true, level_0: true, brands_v2: true },
    extra_capabilities: { brands_v2: true },
    ability: [
      { action: 'true', subject: 'level_0' },
      { action: 'true', subject: 'brands_v2' }
    ],
    airtableData: {
      record_id: Math.floor(Math.random() * 20000),
      'Razão social': Math.floor(Math.random() * 20000),
      Responsável: '',
      CNPJ: '',
      ID: Math.floor(Math.random() * 20000),
      Email: 'seuemaildecontanto@email.com',
      balance_id: [''],
      n8n_wp_username: '',
      public_id: '',
      n8n_corporate_name: '',
      n8n_responsible_name: '',
      n8n_email: '',
      n8n_cnpj: '',
      is_parent: 1,
      isComplete: 0,
      payment_method: ['Pix']
    }
  }
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: action.data,
        [action.config.storageTokenKeyName]:
          action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]:
          action[action.config.storageRefreshTokenKeyName]
      }

    case 'LOGOUT':
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: {}, ...obj }

    // ** TO DO: Fix state for user data... ;-;

    case 'UPDATE_AIRTABLE_DATA':
      return {
        ...state,
        userData: {
          ...state.userData,
          userData: {
            ...state.userData.userData,
            airtableData: {
              ...state.userData.userData.airtableData,
              ...action.data
            }
          }
        }
      }
    case 'SET_SUBSCRIPTION':
      return {
        ...state,
        userData: {
          ...state.userData,
          subscription: action.data
        }
      }

    default:
      return state
  }
}

export default authReducer
