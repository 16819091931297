// ** Logo
import logo from '@src/assets/images/logo/logo.svg'

const SpinnerComponent = ({ height = '' }) => {
  return (
    <div
      className={['fallback-spinner', !height ? 'vh-100' : ''].join(' ')}
      style={{
        height
      }}
    >
      <img
        className="fallback-logo"
        src={logo}
        style={{ width: '90px', height: '90px' }}
        alt="logo"
      />
      <div className="loading pt-3">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
