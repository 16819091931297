// ** Initial State
const initialState = {
  shippings: [],
  total: 0,
  params: { q: '', page: 1, perPage: 10 },
  totalList: 0,
  filterOptions: [],
  summary: {},
  isLoaded: true,
  isError: false
}

// 1

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA_SHIPPING':
      return {
        ...state,
        offset: action.offset,
        shippings: [...state.shippings, ...action.data],
        total: action.total,
        params: action.params,
        totalList: action.totalList,
        filterOptions: action.filterOptions,
        isLoaded: true,
        isError: false,
        summary: action.summary
      }
    case 'CLEAR_DATA_SHIPPING':
      return {
        ...state,
        shippings: [],
        total: 0,
        params: initialState.params,
        totalList: 0,
        filterOptions: [],
        isLoaded: false,
        isError: false,
        summary: {}
      }
    case 'ERROR_SHIPPING':
      return {
        ...state,
        isLoaded: false,
        isError: true
      }
    default:
      return state
  }
}

export default DataTablesReducer
