import mock from '../mock'

const data = {
  categories: [
    'Armazenamento',
    'Produtos e Personalização',
    'Envios',
    'Suporte',
    'Aplicação e Automação'
  ],
  headers: {
    category: 'category',
    starter: 'Starter (R$0)',
    light: 'Light (R$297)',
    basic: 'Basic (R$697)',
    pro: 'Pro (R$1997)',
    enterprise: 'Enterprise (R$3897)'
  },
  cards: [
    {
      title: 'Light',
      description: 'Para quem tá começando',
      value: 297,
      benefits: [
        '300 itens armazenados',
        'Até 5 SKUs no inventário',
        'Montagem de kits',
        'Acesso ao catálogo',
        'Envios ilimitados',
        'Fretes não inclusos'
      ]
    },
    {
      title: 'Basic',
      description: 'Para quem tá crescendo',
      value: 697,
      benefits: [
        '1.000 itens armazenados',
        'Até 6 SKUs no inventário',
        'Montagem de kits',
        'Acesso ao catálogo',
        'Envios ilimitados',
        'Fretes não inclusos'
      ]
    },
    {
      title: 'Pro',
      description: 'Tudo do Basic, mais...',
      popular: true,
      value: 1997,
      benefits: [
        '5.000 itens armazenados',
        'Até 10 SKUs no inventário',
        'Produtos de terceiros',
        'Gerente de CS Dedicado',
        'Envios ilimitados',
        'Fretes não inclusos'
      ]
    },
    {
      title: 'Enterprise',
      description: 'Tudo do Pro, mais...',
      value: 3897,
      benefits: [
        '10.000 itens armazenados',
        'Até 25 SKUs no inventário',
        'Consultorias grátis',
        'Amostras grátis',
        'Automações personalizadas',
        'Fretes não inclusos'
      ]
    }
  ],
  pricing: [
    {
      type: 'Armazenamento',
      benefits: [
        {
          category: 'Quantidade de itens que podem ser armazenados',
          starter: '0',
          light: '300',
          basic: '1000',
          pro: '5000',
          enterprise: '10000'
        },
        {
          category: 'Investimento por item excedente',
          starter: '',
          light: 'R$ 1,00/item',
          basic: 'R$ 1,00/item',
          pro: 'R$ 1,00/item',
          enterprise: 'R$ 1,00/item'
        },
        {
          category: 'Quantidade de SKUs que podem ser armazenados',
          starter: '0',
          light: '5',
          basic: '6',
          pro: '10',
          enterprise: '25'
        },
        {
          category: 'Investimento por SKU excedente',
          starter: '',
          light: 'R$20,00/SKU',
          basic: 'R$20,00/SKU',
          pro: 'R$20,00/SKU',
          enterprise: 'R$20,00/SKU'
        },
        {
          category: 'Montagem de kits (picking, packing e expedição)',
          starter: '',
          light: true,
          basic: true,
          pro: true,
          enterprise: true
        }
      ]
    },
    {
      type: 'Produtos e Personalização',
      benefits: [
        {
          category: 'Catálogo com +3.000 produtos',
          starter: true,
          light: true,
          basic: true,
          pro: true,
          enterprise: true
        },
        {
          category: 'Crie Kits personalizados',
          starter: true,
          light: true,
          basic: true,
          pro: true,
          enterprise: true
        },
        {
          category: 'Amostras virtuais de alta resolução',
          starter: true,
          light: true,
          basic: true,
          pro: true,
          enterprise: true
        },
        {
          category: 'Envie-nos seus brindes existentes para começar',
          starter: '',
          light: '',
          basic: '',
          pro: true,
          enterprise: true
        },
        {
          category: 'Consultoria em Brindes Personalizados',
          starter: '',
          light: '',
          basic: '',
          pro: '',
          enterprise: true
        },
        {
          category: 'Itens totalmente personalizados',
          starter: '',
          light: '',
          basic: '',
          pro: '',
          enterprise: true
        },
        {
          category: 'Amostras de produtos grátis',
          starter: '',
          light: '',
          basic: '',
          pro: '',
          enterprise: true
        }
      ]
    },
    {
      type: 'Envios',
      benefits: [
        {
          category: 'Custo de envio (frete)',
          starter: 'A partir de R$18,00',
          light: 'A partir de R$18,00',
          basic: 'A partir de R$18,00',
          pro: 'A partir de R$18,00',
          enterprise: 'A partir de R$18,00'
        },
        {
          category: 'Envio 100% assegurado',
          starter: true,
          light: true,
          basic: true,
          pro: true,
          enterprise: true
        },
        {
          category: 'Expedição por múltiplas transportadoras',
          starter: true,
          light: true,
          basic: true,
          pro: true,
          enterprise: true
        },
        {
          category: 'Dropshipping',
          starter: '',
          light: true,
          basic: true,
          pro: true,
          enterprise: true
        },
        {
          category: 'Tela para rastreio de envios',
          starter: '',
          light: true,
          basic: true,
          pro: true,
          enterprise: true
        },
        {
          category: 'Manuseio de devoluções e reabastecimento gratuitos',
          starter: '',
          light: true,
          basic: true,
          pro: true,
          enterprise: true
        },
        {
          category: 'Envios com produtos de terceiro',
          starter: '',
          light: '',
          basic: '',
          pro: true,
          enterprise: true
        }
      ]
    },
    {
      type: 'Suporte',
      benefits: [
        {
          category: 'Onboarding com Especialista em Implementação',
          starter: '',
          light: true,
          basic: true,
          pro: true,
          enterprise: true
        },
        {
          category: 'Suporte ao cliente final e devoluções',
          starter: '',
          light: true,
          basic: true,
          pro: true,
          enterprise: true
        },
        {
          category: 'Gerente de Sucesso do Cliente Dedicado',
          starter: '',
          light: '',
          basic: '',
          pro: true,
          enterprise: true
        }
      ]
    },
    {
      type: 'Aplicação e Automação',
      benefits: [
        {
          category: 'Acesso ao Dashboard BeUni',
          starter: true,
          light: true,
          basic: true,
          pro: true,
          enterprise: true
        },
        {
          category: 'Gerenciamento de estoque on-line',
          starter: '',
          light: true,
          basic: true,
          pro: true,
          enterprise: true
        },
        {
          category: 'Refazer pedido on-line e fácil',
          starter: '',
          light: true,
          basic: true,
          pro: true,
          enterprise: true
        },
        {
          category: 'Importação de endereço CSV',
          starter: '',
          light: true,
          basic: true,
          pro: true,
          enterprise: true
        },
        {
          category: 'Envios direto e pedidos de eventos',
          starter: true,
          light: true,
          basic: true,
          pro: true,
          enterprise: true
        },
        {
          category: 'Automação de envio de brindes',
          starter: '',
          light: '',
          basic: '',
          pro: true,
          enterprise: true
        },
        {
          category: 'Contratar',
          starter: 'button',
          light: 'button',
          basic: 'button',
          pro: 'button',
          enterprise: 'button'
        }
      ]
    }
  ]
}

mock.onGet('/pricing/data').reply(() => [200, data])
