// ** UseJWT import to get config
import { getPlan } from '@src/api/atlas/subscription'
import useJwt from '@src/auth/jwt/useJwt'
import { history } from '@src/history'

const jwtConfig = useJwt.jwtConfig

export const hydrateSubscription = () => {
  return (dispatch) => {
    const { accountId } = JSON.parse(localStorage.getItem('userData'))

    if (accountId) {
      getPlan(accountId)
        .then((response) => {
          const { data } = response

          if (data) {
            dispatch({
              type: 'SET_SUBSCRIPTION',
              data: data
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}

// ** Handle User Login
export const handleLogin = (data) => {
  return (dispatch) => {
    const { accountId } = data.userData

    if (accountId) {
      getPlan(accountId)
        .then((response) => {
          const { data } = response

          if (data) {
            dispatch({
              type: 'SET_SUBSCRIPTION',
              data: data
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

    dispatch({
      type: 'LOGIN',
      data: data,
      config: jwtConfig,
      [jwtConfig.storageTokenKeyName]: data[jwtConfig.storageTokenKeyName],
      [jwtConfig.storageRefreshTokenKeyName]:
        data[jwtConfig.storageRefreshTokenKeyName]
    })

    dispatch({
      type: 'HANDLE_MENU_HIDDEN',
      value: false
    })

    // ** Add to user, accessToken & refreshToken to localStorage
    localStorage.setItem('userData', JSON.stringify(data.userData))
    useJwt.setToken(data.accessToken)
    useJwt.setRefreshToken(data.refreshToken)
  }
}

// ** Handle User Logout
export const handleLogout = () => {
  return (dispatch) => {
    dispatch({
      type: 'LOGOUT',
      [jwtConfig.storageTokenKeyName]: null,
      [jwtConfig.storageRefreshTokenKeyName]: null
    })
    dispatch({
      type: 'HANDLE_MENU_HIDDEN',
      value: true
    })
    // ** Remove user, accessToken & refreshToken from localStorage
    localStorage.removeItem('userData')
    localStorage.removeItem(jwtConfig.storageTokenKeyName)
    localStorage.removeItem(jwtConfig.storageRefreshTokenKeyName)

    window.location.href = '/login'
  }
}
