import { initialState as SettingsState } from '@store/reducers/settings'

const initialState = {
  products: [],
  categories: [],
  wishlist: [],
  cart: [],
  swagPacks: [],
  checkout: {
    uploads: [],
    client_email: '',
    orderDeadline: '',
    ShippingDeadline: '',
    companyLogo: [],
    details: '',
    use_our_logistics: '',
    extra_items: ''
  },
  productDetail: {},
  productDetailloading: false,
  params: {
    q: '',
    category: '',
    min: 0,
    max: 99999,
    sortBy: 'featured',
    page: 1,
    perPage: 54
  },
  totalProducts: 0,
  totalSwagPacks: 0,

  // ** New catalogueV2 states

  catalogueV2: {
    params: {
      kitManager: true,
      perPage: 350
      // load: 'variations'
    },
    products: [],
    categories: [],
    totalProducts: 0,
    selectedProducts: [],
    packAmount: SettingsState?.products?.minimumQuantity || 50
  },

  origin: 'dashboard',
  company_logo_error: null
}

const ecommerceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PRODUCTS':
      return {
        ...state,
        products: action.data.products,
        params: action.params,
        totalProducts: action.data.total
      }

    case 'GET_CATEGORIES':
      return { ...state, categories: action.data }

    // ** Catalogue v2 actions

    case 'GET_CATALOGUE_V2_PRODUCTS':
      return {
        ...state,
        catalogueV2: {
          ...state.catalogueV2,
          products: action.data.products,
          totalProducts: action.data.total,
          params: action.data.params
        }
      }

    case 'GET_CATALOGUE_V2_CATEGORIES':
      return {
        ...state,
        catalogueV2: {
          ...state.catalogueV2,
          categories: action.data
        }
      }

    case 'SET_CATALOGUE_V2_SELECTED_PRODUCTS':
      return {
        ...state,
        catalogueV2: {
          ...state.catalogueV2,
          selectedProducts: action.data.products
        }
      }

    case 'UPDATE_CATALOGUE_V2_PRODUCT_AMOUNT':
      return {
        ...state,
        catalogueV2: {
          ...state.catalogueV2,
          selectedProducts: action.data.selectedProducts
        }
      }

    case 'UPDATE_CATALOGUE_V2_PACK_AMOUNT':
      return {
        ...state,
        catalogueV2: {
          ...state.catalogueV2,
          packAmount: action.data.packAmount,
          selectedProducts: action.data.selectedProducts
        }
      } // ** End of catalogue v2 actions

    case 'SET_ORDER_ORIGIN':
      return { ...state, origin: action.data.origin }

    case 'GET_CART':
      return { ...state, cart: action.data.cart }

    case 'ADD_TO_CART':
      let data = { ...state, cart: state.cart }
      data.cart.push({ ...action.data })
      return { ...data }

    case 'OVERRIDE_CART':
      return { ...state, cart: action.data.cart }

    case 'CLEAR_CART':
      let clearedCartData = { ...state, cart: [] }
      return { ...clearedCartData }

    case 'GET_PRODUCT_DETAIL':
      return {
        ...state,
        productDetail: action.data.product ?? {},
        err: action.data.error ?? null
      }

    case 'GET_PRODUCT_LOADING':
      return {
        ...state,
        productDetailloading: action.data.loading
      }

    case 'CLEAR_GET_PRODUCT_ERROR': {
      return {
        ...state,
        err: null
      }
    }

    case 'CLEAR_PRODUCT':
      let clearedProductData = { ...state, productDetail: {}, err: null }
      return { ...clearedProductData }

    case 'CREATE_ORDER':
      return { ...state, cart: [] }

    case 'UPDATE_CHECKOUT':
      return { ...state, checkout: action.data.checkout }

    case 'GET_SWAG_PACKS':
      return {
        ...state,
        swagPacks: action.data.swagPacks,
        totalSwagPacks: action.data.total
      }
    case 'SELECT_SWAG_PACK':
      return {
        ...state,
        productDetail: action.data.productDetail
      }

    case 'SET_COMPANY_LOGO_ERROR':
      return {
        ...state,
        company_logo_error: action.data
      }

    default:
      return state
  }
}

export default ecommerceReducer
