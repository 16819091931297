// import { selectedItemsExample } from './initialState.test'

const initialState = {
  packages: [],
  isLoading: true,
  isError: null,
  params: {
    q: '',
    perPage: 10,
    page: 1,
    key: 'id',
    direction: 'asc'
  }
}

const packageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PACKAGES_REQUEST':
      return {
        ...state,
        isLoading: true,
        isError: null
      }
    case 'SET_ERROR_PACKAGES':
      return {
        ...state,
        isLoading: false,
        isError: true
      }
    case 'GET_PACKAGES':
      return {
        ...state,
        packages: action.data.packages,
        params: action.data.params,
        isLoading: false,
        isError: null
      }

    case 'DELETE_PACKAGE':
      return {
        ...state,
        packages: state.packages.filter(
          (item) => item.recordId !== action.data.id
        )
      }

    default:
      return state
  }
}

export default packageReducer
