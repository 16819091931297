// import InventoryTest from './inventory.test.json'

import { groupedBy, sortProducts, stockBy } from '../../utils/filters'

const initialState = {
  products: [],
  activeView: 'grid',
  isLoading: false,
  isError: null,
  params: {
    hasStock: '',
    groupBy: 'all',
    sortBy: 'ascending',
    q: '',
    page: 1,
    perPage: 21
  },
  summary: {
    SKUsInStock: 0,
    SKUsItemsBelow15: [],
    SKUsLowStock: 0,
    SKUsOutOfStock: 0,
    quantityInStock: 0,
    totalSKUs: 0,
    totalSKUsIsFromOutside: 0
  },
  raw: [],
  categories: [],
  sectionId: '',
  numOfItens: 0,
  csvBreakdown: null
}

const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'GET_INVENTORY':
      const { groupBy, hasStock } = action.params
      return {
        ...state,
        products: action.data.products,
        sectionId: action.data.sectionId,
        numOfItens: action.data.numOfItens,
        raw: groupBy === 'all' && hasStock === '' ? action.data.raw : state.raw,
        summary: action.data.summary,
        categories: action.data.categories,
        isError: null,
        isLoading: false
      }
    case 'SET_ERROR':
      return {
        ...state,
        isLoading: false,
        isError: true
      }
    case 'SET_STOCK_BREAKDOWN':
      return {
        ...state,
        csvBreakdown: action.data
      }

    case 'GET_PRODUCT':
      return { ...state, productDetail: action.data.product }

    case 'CLEAR_PRODUCT':
      let clearedProductData = { ...state, productDetail: {} }
      return { ...clearedProductData }

    case 'CREATE_ORDER':
      return { ...state, cart: [] }

    // ** Filter, group and sort products

    case 'SET_INVENTORY_FILTER':
      return {
        ...state,
        params: { ...state.params, hasStock: action.data.filter }
      }

    case 'SET_INVENTORY_GROUP':
      const grouped = groupedBy({
        groupBy: action.data.group,
        raw: state.raw,
        products: state.raw,
        hasStock: state.params.hasStock
      })

      return {
        ...state,
        sectionId: action.data.group === 'all' ? '' : action.data.group,
        params: { ...state.params, groupBy: action.data.group },
        products: grouped
      }

    case 'SET_INVENTORY_SORT':
      const sorted = sortProducts({
        groupBy: state.params.groupBy,
        products: state.products,
        sortBy: action.data.sort
      })
      return {
        ...state,
        products: sorted,
        params: { ...state.params, sortBy: action.data.sort }
      }

    case 'SET_VIEW':
      return {
        ...state,
        activeView: action.data.view
      }
    case 'FILTER_BY_STOCK': {
      // change to filter stock
      const filter = action.data.filter

      const filtered = stockBy({
        raw: state.raw,
        stockBy: filter
      })

      return {
        ...state,
        products: filtered,
        sectionId: '',
        params: {
          ...state.params,
          groupBy: 'all',
          hasStock: filter
        }
      }
    }

    case 'SEARCH_BY_NAME': {
      const { params } = action.data

      const filterBy = state.raw.filter((item) => {
        return item.itemName.toLowerCase().includes(params.query.toLowerCase())
      })

      return {
        ...state,
        products: filterBy,
        sectionId: '',
        params: {
          ...state.params,
          groupBy: 'all',
          hasStock: ''
        }
      }
    }

    case 'CLEANUP_INVENTORY':
      return {
        ...initialState
      }

    default:
      return state
  }
}

export default inventoryReducer
