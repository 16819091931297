import atlasApi from '..'

const routePath = '/brands/v2/feedbacks'

export const sendFeedback = async (feedback) => {
  const payload = new FormData()
  Object.entries(feedback).forEach(([key, value]) => {
    payload.append(key, value)
  })
  try {
    const response = await atlasApi.post(routePath, feedback)
    return response
  } catch (error) {
    console.error(error.response)
    return error.response
  }
}
