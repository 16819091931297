import thunk from 'redux-thunk'
// import * as ReduxStore from '@uppy/store-redux'
import createDebounce from 'redux-debounced'
import rootReducer from '../reducers/rootReducer'
import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import * as Sentry from '@sentry/react'

import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'

// ** init middleware
const middleware = [thunk, createDebounce()]

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export function purgeStore(keys = persistConfig.whitelist) {
  persistor.purge(keys)
}

//selectedProducts

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['orders', 'ecommerce'],
  whitelist: ['auth', 'settings', 'logistic'],
  stateReconciler: autoMergeLevel2
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

// ** Create store
const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(...middleware), sentryReduxEnhancer)
)

const persistor = persistStore(store)

export { store, persistor }
