import React, { memo, useEffect, useMemo, useState } from 'react'
import ScreenshotButton from '@components/screenshot-button-headless'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { sendFeedback } from '@src/api/atlas/feedback'
import Error from '@src/views/pages/misc/Error'
import { Button } from 'reactstrap'
import { purgeStore } from '@store/storeConfig/store'
import html2canvas from 'html2canvas'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, screenshot: null, error: null }

    this.state.screenshot = null
  }
  handleScreenshot = (s) => {
    this.setState({ screenshot: s })
  }

  handleScreenshotTaken = async () => {
    const payload = {
      client_id: this.props?.state?.auth?.userData?.userData?.record_id || '',
      description:
        `error:\n \`\`\`${this.state.error.message}\`\`\`  error_cause:\n \`\`\`${this.state.error.cause}  \`\`\` \n   stackTrace:\n  \`\`\`${this.state.error.stack}\`\`\` \n` +
        'state:\n```' +
        JSON.stringify(this.props.state.state) +
        '```',
      screenshot: this.state.screenshot,
      // * include path with query params
      path:
        window.location.pathname + window.location.search + '||-error-boundary',
      touchPoint: 'feedback-button'
    }
    console.log('payload', payload)

    const response = await sendFeedback(payload)
    if (!response || response?.status !== 200) {
      throw new Error(response.data.message)
    }
    return response
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    // console.log("error", error)
    console.log('error', error)

    return { hasError: true, error: error }
  }

  componentDidUpdate(prevProps, prevState) {
    // Your custom function that you want to trigger when a child component re-renders

    if (prevState.hasError !== this.state.hasError) {
      this.handleScreenshotTaken()
    }
  }

  render() {
    return <Sentry.ErrorBoundary
      // showDialog={true}
      // dialogOptions={
      //   {
      //     title: 'Ops, parece que algo deu errado.',
      //     subtitle: 'Por favor, tente novamente.',
      //     subtitle2: 'Se o problema persistir, entre em contato com o suporte.',
      //   }
      // }
        fallback={
          <div
            style={{
              height: 'inherit',
              padding: '20em',
              display: 'grid',
              gap: '2em',
              justifyContent: 'center',
              alignSelf: 'center',
              margin: 'auto'
            }}
          >
            <h1>"ops, parece que algo deu errado."</h1>
            <Button
              style={{ margin: '3em' }}
              onClick={() => {
                this.props.reset()
                window.location.reload()
              }}
            >
              Recarregar a página
            </Button>
          </div>
        }
      >
        <FunctionalErrorBoundary
          {...{
            ...this.props,
            handleTakeScreenshot: this.handleScreenshot
          }}
        />
      </Sentry.ErrorBoundary>
    
  }
}

function mapStateToProps(state) {
  const desiredState = {
    state: {
      settings: state.settings,
      ecommerce: {
        checkout: state.ecommerce.checkout
      },
      // inventory: state.inventory,
      // logistics: state.logistics,
      // packages: state.packages,
      orders: {
        // selectedOrder: state.orders.selectedOrder,
        confirm: state.orders.confirm,
        tracking: state.orders.tracking,
        proposal: state.orders.proposal
      }
      // shipments: state.shipments,
      // shippingBatches: state.shippingBatches
    },
    auth: { ...state.auth }
  }
  return {
    state: desiredState
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    reset: () => purgeStore()
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(memo(ErrorBoundary))

const FunctionalErrorBoundary = (props) => {
  const handleScreenshot = async () => {
    const canvas = await html2canvas(document.querySelector('html'), {
      height: window.innerHeight,
      width: window.innerWidth
    })

    const base64image = canvas.toDataURL('image/png')

    props.handleTakeScreenshot(base64image)
  }
  // useMemo(() => {
  //   setTimeout(() => {
  //     handleScreenshot()

  //   }, 200);

  // }, [props.state])

  // create a debounced version of the function
  // const debouncedHandleScreenshot = useMemo(() => {
  //   return _.debounce(handleScreenshot, 2000)
  // }, [])

  // // call the debounced function instead of the original function
  // useMemo(() => {
  //   debouncedHandleScreenshot()
  // }, [props.state])

  return props.children
}
