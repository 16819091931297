import atlasApi from '..'
const subscriptionPrefix = '/brands/v2/subscription'
const enterprise = '/brands/v2/enterprise'

const postPlan = async (email, plan) => {
  // console.log('oi')
  try {
    const response = await atlasApi.post(`${subscriptionPrefix}/subscribe`, {
      email,
      plan
    })

    if (response.status === 200) {
      return [200, response]
    }
  } catch (error) {
    // console.log(error)
    return [error.response.status, error]
  }
}
/**
 * Retrieve user subscription from the API.
 *
 * @returns {Promise<AxiosResponse<any>>} Axios response.
 */

const getPlan = async (user_id) => {
  try {
    const response = await atlasApi.get(`${enterprise}?client_id=${user_id}`, {
      params: {
        include: 'subscription,transactions'
      }
    })

    if (response.status === 200) {
      return {
        status: 200,
        data: response.data
      }
    }
  } catch (error) {
    return {
      status: error.response.status,
      data: error
    }
  }
}

/**
 * Retrieve user subscription from the API.
 * @param {string} user_id User ID.
 * @param {string} include Include.
 * @returns {Promise<AxiosResponse<any>>} Axios response.
 * @example
 * const response = await getSubscription('user_id', 'subscription,transactions')
 * console.log(response)
 * // {
 * //   status: 200,
 * //   data: ...
 * // }
 *
 */

const getSubscription = async (user_id, include) => {
  try {
    const response = await atlasApi.get(enterprise, {
      params: {
        include: include,
        client_id: user_id
      }
    })

    if (response.status === 200) {
      return {
        status: 200,
        data: response.data
      }
    }
  } catch (error) {
    return {
      status: error.response.status,
      data: error
    }
  }
}

export { postPlan, getPlan, getSubscription }
