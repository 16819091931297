import mock from '../mock'
import jwt from 'jsonwebtoken'

import atlasApi from '../apiService'

// ! These two secrets shall be in .env file and not in any other file
const jwtConfig = {
  secret: 'dd5f3089-40c3-403d-af14-d0c228b05cb4',
  refreshTokenSecret: '7c4c1c50-3230-45bf-9eae-c9b2e401c767',
  expireTime: '10m',
  refreshTokenExpireTime: '10m'
}

mock.onPost('/jwt/login').reply(async request => {
  const { email, password } = JSON.parse(request.data)

  let error = {
    email: ['Something went wrong']
  }
  let accessToken 
  let refreshToken
  let userData

  try {
    const data =  await atlasApi.post("/auth/validate", {
      email,
      password
    }).then(res => res.data)

    accessToken = jwt.sign({ id: data.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })
    refreshToken = jwt.sign({ id: data.id }, jwtConfig.refreshTokenSecret, {
      expiresIn: jwtConfig.refreshTokenExpireTime
    })
    userData = { ...data }
    userData.ability = []

    Object.entries(userData.capabilities).forEach(ability => { 
      let [key, value] = Object.entries(ability)
      if(key[1] != 'read') {
        userData.ability.push({
          action: value[1].toString() ,
          subject: key[1]
        })
      }
    })

    const response = {
      userData: userData,
      accessToken,
      refreshToken
    }

    return [200, response] 
  } catch(e) {
    error = e
    return [400, { error }]
  }
})

mock.onPut('/jwt/login').reply(async request => {
  const { email, password, passwordConfirmation } = JSON.parse(request.data)

  let error = {
    email: ['Something went wrong']
  }
  let accessToken 
  let refreshToken
  let userData
  
  try {
    const data =  await atlasApi.put("/auth/users", {
      email,
      password,
      new_password: passwordConfirmation
    }).then(res => { 
      return res.data
    })

    accessToken = jwt.sign({ id: data.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })
    refreshToken = jwt.sign({ id: data.id }, jwtConfig.refreshTokenSecret, {
      expiresIn: jwtConfig.refreshTokenExpireTime
    })

    userData= { ...data }
    userData.ability = []

    Object.entries(userData.capabilities).forEach(ability => { 
      let [key, value] =  Object.entries(ability)
      if(key[1] != 'read') {
        userData.ability.push({
          action: value[1].toString() ,
          subject: key[1]
        })
      }
    })

    const response = {
      userData: userData,
      accessToken,
      refreshToken
    }

    return [200, response]
  } catch(e) {
    error = e
    return [400, { error }]
  }
})


mock.onPost('/jwt/register').reply(async  request => {
  let response
  try {
    const { 
      name, 
      email, 
      username, 
      password
    } = JSON.parse(request.data)

    let userData = await atlasApi.post("/auth/users", {
      name,
      email,
      username,
      password,
      roles: ['beuni_brands_free']
    }).then(res => res.data)
      .catch()

    let accessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })
    let refreshToken = jwt.sign({ id: userData.id }, jwtConfig.refreshTokenSecret, {
      expiresIn: jwtConfig.refreshTokenExpireTime
    })

    userData.ability = []

    Object.entries(userData.capabilities).forEach(ability => { 
      let [key, value] =  Object.entries(ability)
      if(key[1] != 'read') {
        userData.ability.push({
          action: value[1].toString() ,
          subject: key[1]
        })
      }
    })

    userData.ability.push({
      action: 'read',
      subject: 'commom'
    })
      
    const user = Object.assign({}, userData)
    response = { user, accessToken }
  } catch(error) {
    return [400, { data:error }]
  }

  return [200, response]
  // if (request.data.length > 0) {
  //   const { email, password, username } = JSON.parse(request.data)
  //   const isEmailAlreadyInUse = data.users.find(user => user.email === email)
  //   const isUsernameAlreadyInUse = data.users.find(user => user.username === username)
  //   const error = {
  //     email: isEmailAlreadyInUse ? 'This email is already in use.' : null,
  //     username: isUsernameAlreadyInUse ? 'This username is already in use.' : null
  //   }

  //   if (!error.username && !error.email) {
  //     const userData = {
  //       email,
  //       password,
  //       username,
  //       fullName: '',
  //       avatar: null,
  //       role: 'admin',
  //       ability: [
  //         {
  //           action: 'manage',
  //           subject: 'all'
  //         }
  //       ]
  //     }

  //     // Add user id
  //     const length = data.users.length
  //     let lastIndex = 0
  //     if (length) {
  //       lastIndex = data.users[length - 1].id
  //     }
  //     userData.id = lastIndex + 1

  //     data.users.push(userData)

  //     const accessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })

  //     const user = Object.assign({}, userData)
  //     delete user['password']
  //     const response = { user, accessToken }

  //     return [200, response]
  //   } else {
  //     return [200, { error }]
  //   }
  // }
})

mock.onPost('/jwt/refresh-token').reply(request => {
  const { refreshToken } = JSON.parse(request.data)

  try {
    const { id } = jwt.verify(refreshToken, jwtConfig.refreshTokenSecret)

    const userData = { ...request.data.users.find(user => user.id === id) }

    const newAccessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expiresIn })
    const newRefreshToken = jwt.sign({ id: userData.id }, jwtConfig.refreshTokenSecret, {
      expiresIn: jwtConfig.refreshTokenExpireTime
    })

    delete userData.password
    const response = {
      userData,
      accessToken: newAccessToken,
      refreshToken: newRefreshToken
    }

    return [200, response]
  } catch (e) {
    const error = 'Invalid refresh token'
    return [401, { error }]
  }
})
