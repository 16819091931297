// import InventoryTest from './inventory.test.json'

const initialState = {
  products: [],
  params: {
    hasStock: true,
    groupBy: 'order',
    sortBy: 'ascending',
    q: '',
    page: 1,
    perPage: 21
  },
  sectionId: 'order',
  numOfItens: 0,
  csvBreakdown: null
}

const myProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MY_PRODUCTS':
      return {
        ...state,
        products: action.data.products,
        sectionId: action.data.sectionId,
        numOfItens: action.data.numOfItens
      }

    // ** Filter, group and sort products

    case 'SET_MY_PRODUCTS_FILTER':
      return {
        ...state,
        params: { ...state.params, hasStock: action.data.filter }
      }

    case 'SET_MY_PRODUCTS_GROUP':
      return {
        ...state,
        params: { ...state.params, groupBy: action.data.group }
      }

    case 'SET_MY_PRODUCTS_SORT':
      return {
        ...state,
        params: { ...state.params, sortBy: action.data.sort }
      }

    case 'CLEANUP_MY_PRODUCTS':
      return {
        ...initialState
      }

    default:
      return state
  }
}

export default myProductsReducer
