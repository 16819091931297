import React from 'react'

// ** Core components
import Avatar from '@components/avatar'

import { toast, Slide } from 'react-toastify'
import { Check, AlertTriangle } from 'react-feather'

// ** Adds a pulse effect in feedback button

export const pulseFeedbackButton = () => {
  const feedbackButton = document.getElementById('feedback-button')
  feedbackButton.classList.add('btn-pulse')

  setTimeout(() => {
    feedbackButton.classList.remove('btn-pulse')
  }, 10000)
}

// ** KEYS

const KEY = {
  cart: 'cart',
  selectedProducts: '@BEUNI:selectedProducts'
}

// ** Render default toast success

export const renderToastSuccess = (message, options = {}) =>
  toast.success(
    <>
      <div className="toastify-header">
        <div className="title-wrapper">
          <Avatar size="sm" color="success" icon={<Check size={12} />} />
          <h6 className="toast-title font-weight-bold">
            {message || 'Sucesso!'}
          </h6>
        </div>
      </div>
    </>,
    {
      transition: Slide,
      hideProgressBar: true,
      autoClose: 7000,
      ...options
    }
  )

// ** Render default toast error

export const renderToastError = (message, options) => {
  toast.error(
    <>
      <div className="toastify-header">
        <div className="title-wrapper">
          <Avatar size="sm" color="danger" icon={<AlertTriangle size={12} />} />
          <h6 className="toast-title font-weight-bold">
            Oops! Algo deu errado 😖
          </h6>
        </div>
      </div>
      <div className="toastify-body">
        <span>
          {message ||
            'Não foi possível realizar a ação! Por favor tente novamente. Caso o erro persista, entre em contato pelo email sos@beuni.com.br'}
        </span>
      </div>
    </>,
    { transition: Slide, hideProgressBar: true, autoClose: 7000 }
  )

  if (options?.feedbackButton) {
    pulseFeedbackButton(message)
  }
}

export const renderToastWarning = (message) =>
  toast.warning(
    <>
      <div className="toastify-header">
        <div className="title-wrapper">
          <Avatar
            size="sm"
            color="warning"
            icon={<AlertTriangle size={12} />}
          />
          <h6 className="toast-title font-weight-bold">Oops! Algo falhou 😖</h6>
        </div>
      </div>
      <div className="toastify-body">
        <span>
          {message ||
            'Não foi possível realizar a ação! Por favor tente novamente. Caso o erro persista, entre em contato pelo email sos@beuni.com.br'}
        </span>
      </div>
    </>,
    { transition: Slide, hideProgressBar: true, autoClose: 7000 }
  )

// ** Turns atlas error messages readable

export const turnErrorMessageReadable = (data) => {
  if (data?.errors) {
    const errorMessages = data.errors.map(
      (error) => error.title || error.detail
    )
    return errorMessages.join(', ')
  }
  return (
    data?.message ||
    data?.error ||
    data?.errorMessage ||
    data?.detail ||
    data?.title
  )
}

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

export const addWorkDays = (startDate, days) => {
  if (isNaN(days)) {
    console.log('Value provided for "days" was not a number')
    return
  }
  if (!(startDate instanceof Date)) {
    console.log('Value provided for "startDate" was not a Date object')
    return
  }
  // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
  var dow = startDate.getDay()
  var daysToAdd = parseInt(days)
  // If the current day is Sunday add one day
  if (dow == 0) daysToAdd++
  // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
  if (dow + daysToAdd >= 6) {
    //Subtract days in current working week from work days
    var remainingWorkDays = daysToAdd - (5 - dow)
    //Add current working week's weekend
    daysToAdd += 2
    if (remainingWorkDays > 5) {
      //Add two days for each working week by calculating how many weeks are included
      daysToAdd += 2 * Math.floor(remainingWorkDays / 5)
      //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
      if (remainingWorkDays % 5 == 0) daysToAdd -= 2
    }
  }
  startDate.setDate(startDate.getDate() + daysToAdd)
  return startDate
}

// ** Return a date with added amount of days
export const addDays = (date, days) => {
  date.setDate(date.getDate() + days)
  return date
}

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// **
export const dateIsoToString = (date) => {
  const dateToAirtable = new Date(date)
  return `${dateToAirtable.getFullYear()}-${
    dateToAirtable.getMonth() + 1
  }-${dateToAirtable.getDate()}`
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}
/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData')
}
export const getUserData = () => {
  return JSON.parse(localStorage.getItem('userData'))
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} roles Role of user
 */
export const getHomeRouteForLoggedInUser = (userData) => {
  const userRoles = userData?.roles || getUserData().roles

  const checkUserRoles = (roleToCheck) => {
    return userRoles?.some((role) => role === roleToCheck)
  }

  if (checkUserRoles('wcfm_vendor')) return '/dashboard/ecommerce'
  if (checkUserRoles('beuni_brands_free')) return '/brands/catalogo'
  if (checkUserRoles('brands_v2')) {
    return '/brands/experience'
  }
  if (checkUserRoles('supplier')) return '/supplier/cotations'
  if (checkUserRoles('beuni_brands')) return '/intro'

  return '/brands/experience'
}

export const checkifUserIsComplete = (userData) => {
  const userIsComplete =
    userData?.isComplete || getUserData().airtableData?.isComplete
  return userIsComplete === 1
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

// ** Cart management methods
export const getCart = () => {
  const rawCart = localStorage.getItem('cart')

  if (rawCart) {
    const cart = JSON.parse(rawCart)
    if (Array.isArray(cart)) {
      return cart
    }
  }

  return []
}

export const addProductToCart = (product) => {
  const rawCart = localStorage.getItem('cart')
  let newCart = [product]

  if (rawCart) {
    const cart = JSON.parse(rawCart)
    if (Array.isArray(cart)) {
      cart.push(product)
      newCart = cart
    }
  }

  localStorage.setItem('cart', JSON.stringify(newCart))
  return newCart
}

export const findProductInCart = (productId) => {
  const rawCart = localStorage.getItem('cart')

  if (rawCart) {
    const cart = JSON.parse(rawCart)
    if (Array.isArray(cart)) {
      const productIdx = cart.findIndex((obj) => {
        // Fix: try first with record_id, if not found, try with id
        const objId = obj.record_id || obj.id
        return objId === productId
      })

      if (productIdx !== -1) {
        return cart[productIdx]
      }
    }
  }
}

export const updateProductInCart = (product) => {
  const rawCart = localStorage.getItem('cart')

  if (rawCart) {
    const cart = JSON.parse(rawCart)
    if (Array.isArray(cart)) {
      const productIdx = cart.findIndex((obj) => {
        // Fix: try first with record_id, if not found, try with id
        const objId = obj.record_id || obj.id
        const productId = product.record_id || product.id
        return objId === productId
      })

      if (productIdx !== -1) {
        cart[productIdx] = {
          ...cart[productIdx],
          ...product
        }
      }

      localStorage.setItem('cart', JSON.stringify(cart))

      return cart
    }
  }
}

export const deleteProductFromCart = (productId) => {
  const rawCart = localStorage.getItem('cart')

  if (rawCart) {
    const cart = JSON.parse(rawCart)
    if (Array.isArray(cart)) {
      const productIdx = cart.findIndex((obj) => {
        const objId = obj.record_id || obj.id
        return objId === productId
      })

      if (productIdx !== -1) {
        cart.splice(productIdx, 1)
      }

      localStorage.setItem('cart', JSON.stringify(cart))
      return cart
    }
  }
}

export const storageSelectedProducts = (selectedProducts) => {
  localStorage.setItem(KEY.selectedProducts, JSON.stringify(selectedProducts))
}

export const updateStorageSelectedProducts = (selectedProducts) => {
  localStorage.setItem(KEY.selectedProducts, JSON.stringify(selectedProducts))
}

export const geSelectedProductsInLocalStorage = () => {
  const rawSelectedProducts = localStorage.getItem(KEY.selectedProducts)
  const selectedProducts = JSON.parse(rawSelectedProducts)

  return selectedProducts ?? null
}

export const clearCart = () => localStorage.removeItem('cart')

export const openInNewTab = (url) => {
  const win = window.open(url, '_blank')
  if (win != null) {
    win.focus()
  }
}

export const getUser = () => {
  const rawUser = localStorage.getItem('userData')

  if (rawUser) {
    const userData = JSON.parse(rawUser)
    return userData
    // let accessToken = localStorage.getItem('accessToken')
    // let refreshToken = localStorage.getItem('refreshToken')

    // return {
    //   userData: { userData: { ...userData }, accessToken, refreshToken },

    //   accessToken,
    //   refreshToken
    // }
  } else {
    return {
      userData: {
        recipient_id: 0,
        id: 0,
        username: 'Visitante',
        first_name: 'Visitante',
        last_name: '',
        name: 'Visitante',
        email: 'seuemaildecontanto@email.com',
        nickname: 'Visitante',
        slug: 'visitor',
        roles: ['brands_v2'],
        capabilities: { read: true, level_0: true, brands_v2: true },
        extra_capabilities: { brands_v2: true },
        ability: [
          { action: 'true', subject: 'level_0' },
          { action: 'true', subject: 'brands_v2' }
        ],
        airtableData: {
          record_id: Math.floor(Math.random() * 20000),
          'Razão social': Math.floor(Math.random() * 20000),
          Responsável: '',
          CNPJ: '',
          ID: Math.floor(Math.random() * 20000),
          Email: 'asdfasdfasdfsdf@asdfasdfasdfsdf.com',
          balance_id: [''],
          n8n_wp_username: '',
          public_id: '',
          n8n_corporate_name: '',
          n8n_responsible_name: '',
          n8n_email: '',
          n8n_cnpj: '',
          is_parent: 1,

          isComplete: 0
        }
      }
    }
  }
}
